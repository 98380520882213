/* FIXES */

fieldset {
  border: 0;
}

md-card {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Allow to use "center" for toast positioning */

.md-center {
  left: 50%;
  transform: translateX(-50%);
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* CUSTOMS */

#splash-page {
  z-index: 99999 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #000;
  pointer-events: auto;
  backface-visibility: hidden;
  transition: opacity linear 0.3s;
}

#splash-page.ng-hide {
  opacity: 0;
}

.el-logo {
  z-index: 1;
}

.el-register {
  z-index: 1;
}

.el-content {
  background: transparent;
}

.el-carousel {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
}

.el-carousel img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.el-footer {
  color: black;
  text-shadow: 0 0 5px white;
}

.el-margin-auto {
  margin: auto;
}

.el-login-form,
.el-lost-password-form {
  width: 340px;
}

.el-login-card {
  min-height: 400px;
}

/** Registration page css */

.captcha > div {
  border: 2px solid transparent;
}

.captcha.captcha-error > div {
  border: 2px solid red;
}
